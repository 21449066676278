import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import Mixin from "@/utils/mixin";
import { searchKeys, columns } from "./config/index";
import { SysAccountApi } from "@/api";
export default {
  components: {},
  data: function data() {
    return {
      loading: false,
      searchKeys: searchKeys,
      createForm: this.$form.createForm(this),
      columns: columns,
      total: 0,
      page: 1,
      size: 20,
      dataSource: [],
      defaultIndex: 0,
      titles: [{
        id: undefined,
        name: "总计"
      }, {
        id: 1,
        name: "月统计"
      }, {
        id: 2,
        name: "周统计"
      }, {
        id: 3,
        name: "日统计"
      }],
      asc: undefined
    };
  },
  mixins: [Mixin],
  computed: {
    getDataWithKey: function getDataWithKey() {
      return function (key) {
        return {};
      };
    },
    type: function type() {
      return this.titles[this.defaultIndex].id;
    },
    typeText: function typeText() {
      return this.titles[this.defaultIndex].name;
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          _this.loading = true;
          var params = _objectSpread(_objectSpread({}, values), {}, {
            type: _this.type,
            size: _this.size,
            page: _this.page,
            ranksort: _this.asc
          });
          SysAccountApi.cbkGrantRank(params).then(function (res) {
            _this.loading = false;
            _this.dataSource = res.list;
            _this.total = res.total;
          }).catch(function () {
            _this.dataSource = [];
            _this.total = 0;
          }).finally(function (e) {
            setTimeout(function () {
              _this.loading = false;
            }, 3000);
          });
        }
      });
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    handleClick: function handleClick(item, index) {
      this.defaultIndex = index;
      this.page = 1;
      this.getList();
    },
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.createForm.resetFields();
      this.page = 1;
      this.getList();
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.asc = sorter.order == "descend" ? 0 : sorter.order == "ascend" ? 1 : undefined;
      this.getList();
    }
  }
};
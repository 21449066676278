var columns = [{
  title: "用户uid",
  dataIndex: "uid",
  key: "uid",
  width: "20%",
  scopedSlots: {
    customRender: "uid"
  }
}, {
  title: "用户昵称",
  dataIndex: "nickname",
  key: "nickname",
  width: "15%",
  scopedSlots: {
    customRender: "nickname"
  }
}, {
  title: "Username",
  dataIndex: "username",
  key: "username",
  width: "15%",
  scopedSlots: {
    customRender: "username"
  }
}, {
  title: "CBK统计数量",
  dataIndex: "rewardAmount",
  key: "rewardAmount",
  width: "20%",
  sorter: true,
  scopedSlots: {
    customRender: "rewardAmount"
  }
}, {
  title: "统计周期",
  dataIndex: "type",
  key: "type",
  width: "10%",
  scopedSlots: {
    customRender: "type"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  //   fixed: "right",
  width: "15%",
  scopedSlots: {
    customRender: "action"
  }
}];
export { columns };
var searchKeys = [{
  key: "username",
  label: "用户Username",
  placeholder: "用户Username",
  required: false,
  rules: [],
  input: true
}, {
  key: "nickname",
  label: "用户昵称",
  placeholder: "用户昵称",
  required: false,
  rules: [],
  input: true
}, {
  key: "uid",
  label: "用户uid",
  placeholder: "用户uid",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };
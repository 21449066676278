var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("DTab", {
    attrs: {
      defaultIndex: _vm.defaultIndex,
      titles: _vm.titles,
      handleClick: _vm.handleClick,
      showNumb: false
    }
  }), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: "uid",
      loading: _vm.loading
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref, index) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record) {
          return _c("div", {
            key: index
          }, [key == "action" ? [_c("router-link", {
            attrs: {
              to: "/cbkflow?uid=".concat(record.uid)
            }
          }, [_vm._v("查看流水")])] : key == "rewardAmount" ? [_c("router-link", {
            attrs: {
              to: "/cbkflow?uid=".concat(record.uid)
            }
          }, [_vm._v(_vm._s(text))])] : key == "uid" ? [_c("router-link", {
            attrs: {
              to: "/user/detail?uid=".concat(record.uid)
            }
          }, [_vm._v(_vm._s(text))])] : key == "nickname" ? [_c("router-link", {
            attrs: {
              to: "/user/detail?uid=".concat(record.uid)
            }
          }, [_vm._v(_vm._s(text))])] : key == "username" ? [_c("router-link", {
            attrs: {
              to: "/user/detail?uid=".concat(record.uid)
            }
          }, [_vm._v(_vm._s(text))])] : key == "type" ? [_vm._v(_vm._s(_vm.typeText))] : [_vm._v(_vm._s(record[key] || "---"))]], 2);
        }
      };
    })], null, true)
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };